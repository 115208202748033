$font-stack: Verdana, sans-serif;
$font-colour: black;
$margin-side: 20%;
$margin-top: 5%;
$pink: #f5b7a6;

.show {
	display: block;
}

.hide {
	display: none;
}


h1 {
	font-size: 1.5em;
}
@media screen and (max-width: 600px) {
	$margin-side:2%;
	h1 {
		font-size: .5em;
	}
}

html {
    font-size: 1.75vh;
	color: $font-colour;
}

body {
	position: relative;
}
body, .container {
	margin:0px;	
}
section {
	font-family: $font-stack;
//	font-family: $font-stack;
	margin-left: $margin-side;
	margin-right: $margin-side;	
	margin-top: $margin-top;
}

.chart-section {
	margin: 0px;
}

menu {
	padding:0px;
	max-width:15%;
}

.post-list {
	li {
		padding-top:70px;
	}
}

.sidebar {
	position: fixed;
	top:100px;
	left:20px;
	display: block;	
	height: 80vh;
	overflow-y: scroll;
//	padding-right:100px;
	
	
	ul {
		list-style: none;
//		padding:20px;
	}
	
	li {
		padding:10px;
		&.active {
			a {
				color : red;	
			}
			
		}
		
		a {
			text-decoration: none;
			color: black;			
		}
		
		
	}
}


.display-none {
	display: none;
}

.pos-sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 50px;
	left: 10px;
}

.main-nav {
//	margin:0px;
	padding:20px;
	background: gray;	
	
	a {
		color: white;
		padding-left: 10px;
		padding-right: 10px;		
		text-decoration:none;
	}
}

img {
	display: block;
	margin: auto;
	width: 80%;
}
.current {
  color: black;
  
}

.pd-top-100 {
	padding-top: 100px;
}
.pd-bot-100 {
	padding-bottom: 100px;
}

h1 {
	text-align:center;
}
